import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Breadcrumbs,
  Collapse,
  IconButton,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {Column} from '../../api/Column';
import {BoxCentered, ButtonExportCsv, InfoLoading} from '../common';
import {getTicketsExport, useTicketsExport} from './hooks/useTicketsExport';
import {transformTicketsForTable} from './utils/transformTicketForTable';

const columnsConfig: Column<Record<string, string | undefined>>[] = [
  {
    id: 'id',
    label: 'ID',
    export: true,
  },
  {
    id: 'created',
    label: 'Ticket Created',
    export: true,
  },
  {
    id: 'creator',
    label: 'Creator',
    export: true,
  },
  {
    id: 'association',
    label: 'Association',
    export: true,
  },
  {
    id: 'category',
    label: 'Category',
    export: true,
  },
  {
    id: 'assignee',
    label: 'Assignee',
    export: true,
  },
  {
    id: 'viewableBy',
    label: 'Viewable By',
    export: true,
  },
  {
    id: 'priority',
    label: 'Priority',
    export: true,
  },
  {
    id: 'labels',
    label: 'Labels',
    export: true,
  },
  {
    id: 'subject',
    label: 'Subject',
    export: true,
  },
  {
    id: 'description',
    label: 'Description',
    export: true,
  },
  {
    id: 'slaBreached',
    label: 'SLA Breached',
    export: true,
  },
  {
    id: 'lastInternalComment',
    label: 'Last Internal Comment',
    export: true,
  },
  {
    id: 'lastPublicComment',
    label: 'Last Public Comment',
    export: true,
  },
  {
    id: 'ticketHistory',
    label: 'Ticket History',
    export: true,
  },
];

export const TicketReports: React.FC = () => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const memoizedDate = useMemo(() => {
    return date ? {year: date.getFullYear(), month: date.getMonth()} : undefined;
  }, [date]);
  const [limit, setLimit] = useState<number>(25);

  const {tickets, loading} = useTicketsExport(memoizedDate, limit === 0 ? undefined : limit);
  const ticketsCsvData = transformTicketsForTable(tickets);

  const handleSetLimit = (e: React.ChangeEvent<{value: unknown}>) => {
    setLimit(Number(e.target.value));
  };

  const handleGetRowsForExport = async () => {
    if (!memoizedDate) return [];
    const tickets = await getTicketsExport(memoizedDate);
    return transformTicketsForTable(tickets);
  };

  return (
    <>
      <Box
        paddingTop={2}
        paddingBottom={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumbs>
          <Box>Ticket Reports</Box>
        </Breadcrumbs>
        <Box sx={{display: 'flex', gap: '16px', alignItems: 'center'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select date"
              views={['year', 'month']}
              value={date}
              onChange={date => setDate(date ?? undefined)}
              renderInput={params => (
                <TextField variant="outlined" size="small" {...params} sx={{width: '200px'}} />
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </LocalizationProvider>
          <ButtonExportCsv
            filename={`all-tickets-${date?.getFullYear()}-${(date?.getMonth() ?? 0) + 1}`}
            separator=";"
            getRows={handleGetRowsForExport}
            disabled={loading}
            columnsConfig={columnsConfig}
          />
        </Box>
      </Box>
      <>
        {loading ? (
          <InfoLoading />
        ) : (
          <Paper>
            {tickets.length === 0 && (
              <BoxCentered height="50vh">
                <Typography>There is no data available.</Typography>
              </BoxCentered>
            )}
            {tickets.length > 0 && (
              <TableContainer component={Paper}>
                <Table aria-label="ticket report table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Ticket Created</TableCell>
                      <TableCell>Creator</TableCell>
                      <TableCell>Association</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Assignee</TableCell>
                      <TableCell>Viewable By</TableCell>
                      <TableCell>Priority</TableCell>
                      <TableCell>Subject</TableCell>
                      <TableCell>SLA Breached</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ticketsCsvData.map(ticket => {
                      return <Row ticket={ticket} />;
                    })}
                  </TableBody>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <Box display="flex" alignItems="center" justifyContent="end" gap={1}>
                        <InputLabel htmlFor="select">Per page</InputLabel>
                        <NativeSelect id="select" value={limit} onChange={handleSetLimit}>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="0">Show All</option>
                        </NativeSelect>
                      </Box>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            )}
          </Paper>
        )}
      </>
    </>
  );
};

function Row({ticket}: {ticket: Record<string, string | undefined>}) {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment key={ticket.id}>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{ticket.created}</TableCell>
        <TableCell>{ticket.creator}</TableCell>
        <TableCell>{ticket.association}</TableCell>
        <TableCell>{ticket.category}</TableCell>
        <TableCell>{ticket.assignee}</TableCell>
        <TableCell>{ticket.viewableBy}</TableCell>
        <TableCell>{ticket.priority}</TableCell>
        <TableCell>{ticket.subject}</TableCell>
        <TableCell>{ticket.slaBreached}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{paddingBottom: 0, paddingTop: 0, borderBottom: !open ? 0 : '1px solid inherit'}}
          colSpan={8}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 1}}>
              {ticket.labels && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Labels
                  </Typography>
                  <Typography variant="body2" sx={{whiteSpace: 'pre-line'}}>
                    {ticket.labels}
                  </Typography>
                </>
              )}
              {ticket.description && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Description
                  </Typography>
                  <Typography variant="body2" sx={{whiteSpace: 'pre-line'}}>
                    {ticket.description}
                  </Typography>
                </>
              )}
              {ticket.history && (
                <>
                  <Typography variant="h6" gutterBottom>
                    History
                  </Typography>
                  <Typography variant="body2" sx={{whiteSpace: 'pre-line', mb: 2}}>
                    {ticket.history}
                  </Typography>
                </>
              )}
              {ticket.lastInternalComment && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Last Internal Comment
                  </Typography>
                  <Typography variant="body2" sx={{whiteSpace: 'pre-line', mb: 2}}>
                    {ticket.lastInternalComment}
                  </Typography>
                </>
              )}
              {ticket.lastPublicComment && (
                <>
                  <Typography variant="h6" gutterBottom>
                    Last Public Comment
                  </Typography>
                  <Typography variant="body2" sx={{whiteSpace: 'pre-line'}}>
                    {ticket.lastPublicComment}
                  </Typography>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
