import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import Link from '@mui/material/Link';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {generatePath, Link as RouterLink} from 'react-router-dom';
import {
  Collections,
  Firebase,
  LinkedTicket,
  Ticket,
  TicketHistoryRecordType,
  TicketSearchItemModel,
} from '../../../..';
import {TICKET_EDIT} from '../../../../constants/routes';
import {BoxEllipsis} from '../../../common';
import {
  FIELD_NAME_DISTINGUISHABLE_ID,
  FIELD_NAME_LINKED_TICKETS,
  FIELD_NAME_SUBJECT,
} from '../../constants/constants';
import {useHistoryRecord} from '../../hooks/useHistoryRecord';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {useTicketHistoryRecord} from '../../hooks/useTicketHistoryRecord';
import {useTicketId} from '../../hooks/useTicketId';
import {LinkedTicketSearch} from './LinkedTicketSearch';
import {LinkedTicketSearchItem} from './LinkedTicketSearchItem';
import {LinkedTicketSearchItemHeader} from './LinkedTicketSearchItemHeader';

export const InputLinkedTickets = () => {
  const {ticketId} = useTicketId();
  const [open, setOpen] = useState(false);
  const {isEditingDisabled} = useIsEditingDisabled();
  const {addHistoryRecord} = useHistoryRecord();
  const {addTicketHistoryRecord} = useTicketHistoryRecord();
  const {register, unregister, setValue} = useFormContext();
  const [linkedTicket, setLinkedTicket] = useState<LinkedTicket | null>();
  const linkedTicketsWatch = useWatch({name: FIELD_NAME_LINKED_TICKETS});
  const distinguishableIdWatch = useWatch({name: FIELD_NAME_DISTINGUISHABLE_ID});
  const subjectWatch = useWatch({name: FIELD_NAME_SUBJECT});
  const ticketExists = useMemo(
    () =>
      Boolean(linkedTicket) &&
      Boolean(
        linkedTicketsWatch &&
          linkedTicket &&
          (linkedTicketsWatch as LinkedTicket[]).find(x => x.id === linkedTicket.id)
      ),
    [linkedTicket, linkedTicketsWatch]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const addCurrentTicketAsLinkedTickets = async (linkedTicket: LinkedTicket) => {
    if (!linkedTicket?.id) {
      return;
    }
    try {
      const ref = Firebase.firestore.collection(Collections.tickets).doc(linkedTicket?.id);
      const snap = await ref.get();
      if (!snap.exists) {
        return;
      }
      const linkedTicketData = snap.data() as Ticket;
      if (linkedTicketData.linkedTickets?.find(x => x.id === ticketId)) {
        //don't add duplicate
        return;
      }
      await ref.set(
        {
          linkedTickets: Firebase.FieldValue.arrayUnion({
            id: ticketId,
            distinguishableId: distinguishableIdWatch,
            subject: subjectWatch,
          } as LinkedTicket),
          updatedAt: Firebase.now(),
        },
        {merge: true}
      );
      await addTicketHistoryRecord(
        linkedTicket.id,
        false,
        `Linked ticket added: ${distinguishableIdWatch ?? ticketId}`,
        TicketHistoryRecordType.LinkedTicket,
        true,
        undefined,
        false
      );
    } catch (error) {
      console.error('Error writing document (InputLinkedTicket): ', error);
      return error;
    }
  };

  const removeCurrentTicketFromLinkedTickets = async (linkedTicket: LinkedTicket) => {
    if (!linkedTicket?.id) {
      return;
    }
    try {
      const ref = Firebase.firestore.collection(Collections.tickets).doc(linkedTicket?.id);
      const snap = await ref.get();
      if (!snap.exists) {
        return;
      }
      const linkedTicketData = snap.data() as Ticket;
      const linkedDataToRemove = linkedTicketData.linkedTickets?.find(x => x.id === ticketId);
      if (!linkedDataToRemove) {
        //ticket isn't marked as linked - do nothing
        return;
      }

      await ref.set(
        {
          linkedTickets: Firebase.FieldValue.arrayRemove(linkedDataToRemove),
          updatedAt: Firebase.now(),
        },
        {merge: true}
      );
      await addTicketHistoryRecord(
        linkedTicket.id,
        false,
        `Unlinked ticket: ${distinguishableIdWatch ?? ticketId}`,
        TicketHistoryRecordType.LinkedTicket,
        true,
        undefined,
        false
      );
    } catch (error) {
      console.error('Error writing document (InputLinkedTicket): ', error);
      return error;
    }
  };

  const saveLinkedTickets = async (linkedTickets: LinkedTicket[] = []) => {
    try {
      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set({linkedTickets: linkedTickets, updatedAt: Firebase.now()}, {merge: true});
      setValue(FIELD_NAME_LINKED_TICKETS, linkedTickets, {shouldValidate: false});
      handleClose();
      setLinkedTicket(null);
    } catch (error) {
      console.error('Error writing document (InputLinkedTicket): ', error);
      return error;
    }
  };

  const handleSubmit = async () => {
    if (linkedTicket) {
      const error = await saveLinkedTickets([...(linkedTicketsWatch ?? []), linkedTicket]);
      if (error) return;
      await addHistoryRecord(
        `Linked ticket added: ${linkedTicket.distinguishableId ?? linkedTicket.id}`,
        TicketHistoryRecordType.LinkedTicket,
        true
      );
      await addCurrentTicketAsLinkedTickets(linkedTicket);
    }
  };

  const handleLabelDelete = async (linkedTicket: LinkedTicket) => {
    const error = await saveLinkedTickets(
      (linkedTicketsWatch ?? []).filter((l: LinkedTicket) => l.id !== linkedTicket.id)
    );
    if (error) return;
    await addHistoryRecord(
      `Unlinked ticket: ${linkedTicket.distinguishableId ?? linkedTicket.id}`,
      TicketHistoryRecordType.LinkedTicket,
      true
    );
    await removeCurrentTicketFromLinkedTickets(linkedTicket);
  };

  const handleRenderOption = useCallback(
    (option: TicketSearchItemModel, _?: Record<string, any>) => {
      if (!option.id) {
        return <LinkedTicketSearchItemHeader />;
      }

      return <LinkedTicketSearchItem key={option.id} ticketSearchItem={option} />;
    },
    []
  );

  useEffect(() => {
    register(FIELD_NAME_LINKED_TICKETS);
    register(FIELD_NAME_DISTINGUISHABLE_ID);
    if (linkedTicketsWatch) {
      setValue(FIELD_NAME_LINKED_TICKETS, linkedTicketsWatch);
    }
    return () => {
      unregister(FIELD_NAME_LINKED_TICKETS);
      unregister(FIELD_NAME_DISTINGUISHABLE_ID);
    };
  }, [register]);

  const handleSearchChange = (option: TicketSearchItemModel | null) => {
    if (!option) return;

    const {id, distinguishableId, subject} = option as TicketSearchItemModel;
    if (!id) {
      return;
    }
    setLinkedTicket({id: id, distinguishableId: distinguishableId, subject: subject ?? ''});
  };

  return (
    <Box display="flex" alignItems="flex-start">
      <Box flex={1}>
        {(linkedTicketsWatch ?? []).map((linkedTicket: LinkedTicket) => (
          <Box key={linkedTicket.id} pt={0.5} pb={1}>
            <Tooltip title={`${linkedTicket.distinguishableId ?? ''}: ${linkedTicket.subject}`}>
              <Chip
                label={
                  <BoxEllipsis alignItems="flex-start" maxWidth={200} mb={0.5}>
                    <Link
                      rel="noopener noreferrer"
                      target="_blank"
                      to={generatePath(TICKET_EDIT, {id: linkedTicket.id})}
                      component={RouterLink}
                      sx={{color: 'inherit', flexGrow: 1, mx: -2, px: 2}}
                      onClick={e => {
                        if (e.ctrlKey || e.metaKey) {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {`${linkedTicket.distinguishableId ?? ''}: ${linkedTicket.subject}`}
                    </Link>
                  </BoxEllipsis>
                }
                onDelete={isEditingDisabled ? undefined : () => handleLabelDelete(linkedTicket)}
                color="primary"
                variant="outlined"
              />
            </Tooltip>
          </Box>
        ))}
        {!linkedTicketsWatch?.length && <Box pt={1}>...</Box>}
      </Box>
      <Box>
        <IconButton onClick={() => setOpen(true)} disabled={isEditingDisabled}>
          <AddIcon />
        </IconButton>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>Add Linked Ticket</DialogTitle>
          <DialogContent>
            <Box mt={2} minWidth={850}>
              <LinkedTicketSearch onChange={handleSearchChange} renderOption={handleRenderOption} />
            </Box>
          </DialogContent>
          <DialogActions sx={{mt: 4, ml: 1}}>
            <Box>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit} disabled={!linkedTicket || ticketExists}>
                Save
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};
