import FilterListIcon from '@mui/icons-material/FilterList';
import InfoIcon from '@mui/icons-material/Info';
import {Grid, IconButton, Tooltip, Typography} from '@mui/material';
import {
  AllMIDs,
  ScheduledJobType,
  SearchCriteria,
  useApiContainer,
  useMidsContainer,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  ExportProps,
  Filters,
  FilterUpdate,
  Loading,
  Square,
  Table,
} from '@ozark/common/components';
import {
  BatchesColumnsConfigRecord,
  getBatchesFormattedDate,
} from '@ozark/functions/src/functions/common/reports/columnConfigs/BatchesColumnConfig';
import {Batch} from '@ozark/functions/src/functions/express/private/types';
import {PaginatedBatchesResponse} from '@ozark/functions/src/functions/express/private/types/PaginatedResponse';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getScheduledReportParameters} from '../../../api/ScheduledReportParameters';
import {useUrlQuery} from '../../../hooks/useUrlQuery';
import {ScheduleReportProps} from '../../common/ButtonEmailReport';
import {Bar, Filter, MidOptionPreselected} from '../common';
import {forceMtdActiveFilter} from '../common/Utils/forceMtdActiveFilter';
import {getFiltersMidFirst} from '../common/Utils/getFiltersMidFirst';
import {forceActiveFilter, formatMinorUnits, useReportingPageStyles} from '../Reporting';
import {BatchesFiltersComponent} from './BatchesFiltersComponent';
import {BatchesFilters} from './Types';

type Props = {
  transactionRouteUrl: string;
} & MidOptionPreselected;

export const Batches = ({transactionRouteUrl, midOptionPreselected}: Props) => {
  const classes = useReportingPageStyles();
  const history = useHistory<{batch: Batch; mid: string}>();
  const [batches, setBatches] = useState<PaginatedBatchesResponse | null>();
  const query = useUrlQuery();
  const urlBatchId = query.get('batchId');
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    limit: 20, // page size
    offset: 1, // page
    order: 'desc',
    orderBy: 'batchDate',
  });
  const [filters, setFilters] = useState<Filter>({});
  const {mids, midsOptions, selectedMid, handleSelectMid, forceSelectMidIfNeeded} =
    useMidsContainer();
  const api = useApiContainer();

  const getAllDataForExport = useCallback(async () => {
    if (!api) return [];

    const pageConfigFull: SearchCriteria = {...searchCriteria, offset: 0, limit: 0};
    const result = await api?.transactions.getBatches(
      pageConfigFull,
      selectedMid,
      Object.values(filters)
    );
    return result?.data ?? [];
  }, [searchCriteria, selectedMid, filters]);

  const exportProps: ExportProps = {
    filename: `batches-report-${selectedMid}`,
    rows: batches?.data,
    columnsConfig,
    getRows: getAllDataForExport,
  };

  const setFiltersAndResetOffset = (_filters: Filter | null, filterUpdate?: FilterUpdate) => {
    if (filterUpdate) {
      setFilters(filters => getFiltersMidFirst(filters, filterUpdate));
    } else {
      setFilters(_filters ?? {});
    }
    setSearchCriteria({...searchCriteria, offset: 1});
  };

  const forceFilterByMid = (newMid: string) => {
    const forcedFilterMid = forceActiveFilter(BatchesFilters, 'mid', '__eq', newMid);
    setFiltersAndResetOffset(null, {updateColumnName: 'mid', filter: forcedFilterMid});
  };

  const forceFilterByMtd = () => {
    const forcedFilter = forceMtdActiveFilter(BatchesFilters, 'actualDateRange');
    setFiltersAndResetOffset(null, {updateColumnName: 'batchDate', filter: forcedFilter});
  };

  useEffect(() => {
    forceFilterByMtd();
  }, []);

  useEffect(() => {
    if (mids.promised || !mids.data) return;
    if (midOptionPreselected) {
      onMidSelect(midOptionPreselected.mid);
    } else {
      forceSelectMidIfNeeded();
    }
  }, [mids, midOptionPreselected, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (selectedMid === AllMIDs) return;

    forceFilterByMid(selectedMid);
  }, [selectedMid]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    getBatches();
  }, [searchCriteria, filters]);

  const getBatches = () => {
    api?.transactions
      .getBatches(searchCriteria, selectedMid, Object.values(filters))
      .then((result: any) => {
        setBatches(result || {});
      })
      .catch(error => {
        console.error('Failed at loading Batches with an error:', error);
        setBatches({} as PaginatedBatchesResponse);
      });
  };

  const handleRowClick = (batch: Batch) => {
    history.push(transactionRouteUrl, {batch, mid: selectedMid});
  };

  const onMidSelect = (mid: string) => {
    if (mid !== selectedMid) {
      handleSelectMid(mid);
      forceFilterByMid(mid);
    }
  };

  const getJobParametersFn = useCallback(() => {
    return getScheduledReportParameters(searchCriteria, filters);
  }, [filters, searchCriteria]);

  const scheduleReportProps: ScheduleReportProps = {
    jobType: ScheduledJobType.midReportingBatches,
    getJobParameters: getJobParametersFn,
  };

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFiltersAndResetOffset(_filters);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFiltersAndResetOffset({...filters, [filter.option.column]: filter});
  };

  useEffect(() => {
    const batchesFilterKeys = BatchesFilters.map(af => af.id);
    for (const key of query.keys()) {
      if (!batchesFilterKeys.includes(key)) continue;
      const batchesFilter = BatchesFilters.find(f => f.id === key);
      if (!batchesFilter) continue;
      if (key === 'mid') {
        const mid = query.get(key);
        if (mid) {
          onMidSelect(mid);
        }
      }
    }
    if (!!urlBatchId) {
      const batchesFilter = BatchesFilters.find(f => f.id === 'id');
      if (!batchesFilter) return;
      handleApplyFilter({
        option: batchesFilter,
        operator: batchesFilter.operators[0],
        value: urlBatchId,
      });
    }
  }, [urlBatchId]);

  if (mids.promised || !mids.data) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Batches"
        midsLoaded
        selectedMid={selectedMid}
        mids={midOptionPreselected ? [midOptionPreselected] : midsOptions}
        onMidSelect={onMidSelect}
        Filters={<Filters options={BatchesFilters} onApplyFilter={handleApplyFilter} />}
        exportProps={exportProps}
        scheduleReportProps={scheduleReportProps}
      />
      {isEmpty(mids.data) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}
      {batches && isEmpty(batches) && (
        <Typography className={classes.noContent}>No Batches</Typography>
      )}
      {batches && !isEmpty(batches) && (
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            {filters && !isEmpty(filters) && (
              <IconButton disabled size="large">
                <FilterListIcon />
              </IconButton>
            )}
            <BatchesFiltersComponent filters={filters} handleDeleteFilter={handleDeleteFilter} />
          </Grid>
          <Grid item xs={4}>
            <Square lines={{'Batched Amount': formatMinorUnits(batches.grossSumAmount)}} center />
          </Grid>
          <Grid item xs={4}>
            <Square
              lines={{
                'Approved Batches': formatMinorUnits(batches.netSumAmount),
              }}
              center
            />
          </Grid>
          <Grid item xs={4}>
            <Square
              lines={{
                Credits: formatMinorUnits(batches.creditsAmount),
              }}
              center
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              stickyHeader
              scrollableBody
              customHeight="75vh"
              columns={columnsConfig}
              data={batches}
              onRowClick={handleRowClick}
              onRetrieveData={handleRetrieveData}
              paginate
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const getFormattedDate = (dateString: string | Date) => {
  return getBatchesFormattedDate(dateString);
};

const columnsConfig: Column<Batch>[] = [
  {
    numeric: false,
    sortable: true,
    selector: row => getFormattedDate(row.batchDate),
    ...BatchesColumnsConfigRecord.batchDate,
  },
  {
    numeric: false,
    sortable: true,
    selector: row => format(utcToZonedTime(new Date(row.statementDate), 'UTC'), 'MMMM yyyy'),
    ...BatchesColumnsConfigRecord.statementDate,
  },
  {
    numeric: false,
    sortable: false,
    ...BatchesColumnsConfigRecord.id,
  },
  {
    numeric: true,
    sortable: true,
    selector: row => {
      return <span style={{color: 'green'}}>{formatMinorUnits(row.batchedAmount)}</span>;
    },
    ...BatchesColumnsConfigRecord.batchedAmount,
  },
  {
    numeric: true,
    sortable: true,
    selector: row => {
      return (
        <span style={{color: row.creditsAmount < 0 ? 'red' : 'black'}}>
          {formatMinorUnits(row.creditsAmount)}
        </span>
      );
    },
    ...BatchesColumnsConfigRecord.creditsAmount,
  },
  {
    info: (
      <>
        <Tooltip
          title="Batches reviewed by risk. A ticket will be opened to you if any action is required otherwise you will receive the deposit as normal"
          sx={{float: 'right', marginLeft: '3px', marginTop: '10px'}}
        >
          <InfoIcon color="info" />
        </Tooltip>
        <Typography sx={{fontSize: 10}}>(Delayed Funding)</Typography>
      </>
    ),
    numeric: true,
    sortable: true,
    selector: row => {
      return (
        <span style={{color: row.rejectsAmount < 0 ? 'red' : 'black'}}>
          {formatMinorUnits(row.rejectsAmount)}
        </span>
      );
    },
    ...BatchesColumnsConfigRecord.rejectsAmount,
  },
  {
    numeric: true,
    sortable: true,
    selector: row => formatMinorUnits(row.approvedBatches),
    ...BatchesColumnsConfigRecord.approvedBatches,
  },
  {
    numeric: true,
    sortable: true,
    selector: row => {
      return row.transactionsCount;
    },
    ...BatchesColumnsConfigRecord.transactionsCount,
  },
  {
    numeric: false,
    sortable: false,
    ...BatchesColumnsConfigRecord.merchantReferenceNumber,
  },
];
