import type {ComponentType, PropsWithChildren} from 'react';
import {
  AssigneeType,
  AssociationType,
  FieldValue,
  Ticket,
  TicketComment,
  TicketHistoryRecord,
  TicketLastCommentInfo,
  TicketStatus,
  TicketTypeAndCategory,
  UniversalTimestamp,
} from '../../..';

export type TicketAttachment = {
  id: string;
  fileName: string;
  filename: string;
  oldFilename: string;
  fileCloudPath: string;
  cloudPath: string;
  createdAt: UniversalTimestamp | FieldValue;
  uid: string;
};

export type NewTicketAttachmentData = Omit<TicketAttachment, 'id'>;

export type InputAssigneeError = keyof typeof AssigneeType | 'type' | 'group';
export type InputAssociationError = keyof typeof AssociationType | 'type';
export type InputCategoryError = keyof TicketTypeAndCategory | 'type';

export type Direction = 'asc' | 'desc';

export type Option = {
  label: string;
  value?: string;
};

export enum TicketsFolderId {
  All = 'all',
  Draft = 'draft',
  Replied = 'replied',
  Open = 'open',
  InProgress = 'doing',
  InProgress24 = 'doing24',
  Closed = 'closed',
  Unresolved = 'unresolved',
  Deleted = 'deleted',
}

export type TFolder = {
  id: TicketsFolderId;
  statusSet: TicketStatus[];
  folderName: string;
  folderTipTitle: string;
  folderTipText: string;
  icon: ComponentType<PropsWithChildren<unknown>> | null;
  italic?: boolean;
  badgeColor?: string;
  indent?: boolean;
  hasDivider?: boolean;
  hasCounter: boolean;
  filterKey?: FilterKey;
};

export type EditorType = 'subject' | 'description' | undefined;

export type StatusFolderProps = {
  folder: TFolder;
  isSelected: boolean;
};

export enum FilterKey {
  moreThen24h = '>24',
}

export interface LastCommentsInfo {
  lastPublicCommentInfo: TicketLastCommentInfo | FieldValue;
  lastInternalCommentInfo: TicketLastCommentInfo | FieldValue;
}

export type TicketWithHistoryAndComments = Ticket & {
  id: string;
  history?: TicketHistoryRecord[];
  comments?: TicketComment[];
  commentsInternal?: TicketComment[];
};
