import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AgentView,
  CallableFunctionName,
  ExportToCsv,
  Firebase,
  ListOption,
  PublishedResidualOptionsResults,
  SearchCriteria,
  TooltipPlacement,
  useAgents,
  useApiContainer,
  useCallable,
  useNotification,
} from '@ozark/common';
import {Column} from '@ozark/common/api/Column';
import {
  ActiveFilter,
  AutoCompleteInputBase,
  FilterOption,
  Filters,
  InlineSelect,
  InlineTypographyGeneric,
  Loading,
  Square,
  Table as DataTable,
  Title,
} from '@ozark/common/components';
import {ResidualAdjustments} from '@ozark/functions/src/functions/callable/dispatch_GetResidualAdjustments';
import {PaginatedResponse} from '@ozark/functions/src/functions/express/private/types/PaginatedResponse';
import {AgentResidual} from '@ozark/functions/src/functions/express/private/types/Residual';
import {parse} from 'date-fns';
import {format, utcToZonedTime} from 'date-fns-tz';
import {isEmpty} from 'lodash';
import startcase from 'lodash/startCase';
import {Fragment, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {currentFormatter, forceActiveFilter, useReportingPageStyles} from '../../reports';
import {CreateManualAdjustmentBtn} from './CreateManualAdjustmentBtn';
import {getAgentScheduleAListOptions, HundredPercent} from './helpers';

const getFormattedDate = (dateString: string | null) => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  return format(utcToZonedTime(date, 'UTC'), 'MM/dd/yyyy');
};

const ResidualsFilters: FilterOption[] = [
  {
    id: 'mid',
    column: 'mid',
    label: 'MID',
    type: 'text',
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
  {
    id: 'dba',
    column: 'dba',
    label: 'DBA',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'agentName',
    column: 'agentName',
    label: 'Agent Name',
    type: 'text',
    operators: [
      {
        id: '__like',
        label: 'contains',
      },
    ],
  },
  {
    id: 'yearMonth',
    column: 'yearMonth',
    label: 'Date',
    type: 'text',
    force: true,
    operators: [
      {
        id: '__eq',
        label: 'equals',
      },
    ],
  },
];

export const DefaultCriteria: SearchCriteria = {
  limit: 10, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'yearMonth',
};

interface AgentPayoutsToDisplay {
  agentName: string;
  description: string;
  amount: number;
  isManual?: boolean;
  agentId: string;
  revShare?: string;
}

type Props = {
  showActiveAgents: boolean;
  canUpdateManualAdjustments: boolean;
  authorizedAgents?: AgentView[];
  masterAgentUid?: string;
  showShareData?: boolean;
};

export const AgentResiduals = ({
  showActiveAgents,
  authorizedAgents,
  masterAgentUid,
  canUpdateManualAdjustments,
  showShareData,
}: Props): JSX.Element => {
  const history = useHistory<{agentId: string; yearMonth: string}>();
  const showNotification = useNotification();
  const classes = useReportingPageStyles();
  const api = useApiContainer();
  const [activeAgents, setActiveAgents] = useState<AgentView[]>([]);
  const {documents: agents} = useAgents();
  const [residuals, setResiduals] = useState<
    | (PaginatedResponse<
        AgentResidual & {boardedAt: string | null; averageTicket: string | null}
      > & {
        bankcardSalesVolume: string;
        bankcardSalesVolumeAmex: string;
        totalIncome: string;
        agentExpense: string;
        agentNet: string;
        agentNetSplit: string;
        subAgentExpense: string;
        subAgentNet: string;
        subAgentNetSplit: string;
        agentProfit: string;
        shareNetSplit: string;
        transactionCount: string;
        transactionCountAmex: string;
        pinDebitSalesVolume: string;
        pinDebitCount: string;
      })
    | null
  >();
  const [loadingResiduals, setLoadingResiduals] = useState(true);
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<{[_: string]: ActiveFilter}>({});
  const [yearMonths, setYearMonths] = useState<string[] | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>();
  const [selectedAgent, setSelectedAgent] = useState<AgentView>();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [agentPayouts, setAgentPayouts] = useState<AgentPayoutsToDisplay[] | null>(null);
  const [canAddManualAdjustments, setCanAddManualAdjustments] = useState(false);
  const [totalAdj, setTotalAdj] = useState(0);
  const [netResidualPaid, setNetResidualPaid] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<any>(0);
  const {getResidualAdjustments, saveResidualManualAdjustments} = useCallable();
  const [manualAdjustmentsWereChanged, setManualAdjustmentsWereChanged] = useState(false);
  const [manualAdjustmentsToDelete, setManualAdjustmentsToDelete] = useState<
    AgentPayoutsToDisplay[]
  >([]);
  const [isSaving, setIsSaving] = useState(false);
  const [inlineSelectOptions, setInlineSelectOptions] = useState<ListOption[]>([]);
  const [isInlineSelectLoading, setIsInlineSelectLoading] = useState(true);
  const [cachedInlineSelectOptions, setCachedInlineSelectOptions] = useState<
    Map<string, ListOption[]>
  >(new Map<string, ListOption[]>());
  const editOnRender = useRef<number | undefined>(undefined);
  const selectOptions = (showActiveAgents ? activeAgents : authorizedAgents) ?? [];

  const scrollToSaveManualAdjustment = () => {
    showNotification(
      'info',
      'There were changes in manual adjustments. To continue please save it'
    );
    setTimeout(() => {
      document.body.scrollIntoView({behavior: 'smooth', block: 'end'});
    }, 300);
  };
  const setSelectedAgentWithChangeValidation = (agent?: AgentView) => {
    if (manualAdjustmentsWereChanged) {
      scrollToSaveManualAdjustment();
      return;
    }
    setSelectedAgent(agent);
  };

  useEffect(() => {
    setCanAddManualAdjustments(!!selectedAgent && canUpdateManualAdjustments);
  }, [selectedAgent]);

  useEffect(() => {
    const paramYearMonth = history.location?.state?.yearMonth;
    const paramAgentId = history.location?.state?.agentId;
    if (!paramAgentId || !paramYearMonth || !yearMonths || !selectOptions) {
      return;
    }

    const agentToSelect = selectOptions.find(x => x.id === paramAgentId);
    if (!agentToSelect) {
      return;
    }

    if (!yearMonths.find(x => x === paramYearMonth)) {
      return;
    }

    setSelectedYearMonth(paramYearMonth);
    const forcedFilter = forceActiveFilter(ResidualsFilters, 'yearMonth', '__eq', paramYearMonth);
    setFilters({...filters, yearMonth: forcedFilter});
    setSelectedAgent(agentToSelect);
  }, [
    yearMonths,
    selectOptions,
    history.location?.state?.agentId,
    history.location?.state?.yearMonth,
  ]);

  useEffect(() => {
    if (!selectedYearMonth) {
      setAgentPayouts(null);
      return;
    }

    setAgentPayouts([]);

    getResidualAdjustments({
      yearMonth: selectedYearMonth,
      agentId: selectedAgent?.id,
      appendManualAdjustments: true,
    }).then(result => {
      if (result.status === 'ok') {
        const sortedData = result.data.sort((a, b) => a.agentId.localeCompare(b.agentId));
        setAgentPayouts(sortedData);
      }
    });
  }, [yearMonths, selectedAgent?.id, selectedYearMonth]);

  useEffect(() => {
    if (!agentPayouts || !residuals) {
      setTotalAdj(0);
      setNetResidualPaid(0);
      return;
    }

    const nextTotalAdj = agentPayouts.reduce(
      (previousValue, current) => previousValue + current.amount,
      0
    );

    setTotalAdj(nextTotalAdj);
    setNetResidualPaid(parseFloat(residuals.agentNetSplit || '0') + nextTotalAdj);
  }, [residuals, agentPayouts]);

  useEffect(() => {
    (async () => {
      const getPublishedResidualOptions = Firebase.functions.httpsCallable(
        CallableFunctionName.getPublishedResidualOptions
      );
      const result: {data: PublishedResidualOptionsResults} = await getPublishedResidualOptions({});
      if (!result?.data?.options) {
        setLoadingResiduals(false);
        return;
      }

      const options = result.data.options.sort();
      setSelectedYearMonth(options[options.length - 1]);
      setYearMonths(options);
      if (!options.length) {
        setLoadingResiduals(false);
      }

      const forcedFilter = forceActiveFilter(
        ResidualsFilters,
        'yearMonth',
        '__eq',
        options[options.length - 1]
      );
      setFilters({...filters, yearMonth: forcedFilter});
    })();
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (loadingResiduals) return;
    setTimeout(() => {
      if (ref?.current) {
        setWidth(ref.current.offsetWidth);
      }
    }, 200);
  }, [loadingResiduals]);

  const handleInlineSelectOpen = (agentId: string) => async () => {
    setInlineSelectOptions([]);
    setIsInlineSelectLoading(true);

    try {
      const cachedList = cachedInlineSelectOptions.get(agentId);
      if (cachedList) {
        setInlineSelectOptions(cachedList);
        return;
      }
      const listOptions = await getAgentScheduleAListOptions(agentId);
      setInlineSelectOptions(listOptions);
      setCachedInlineSelectOptions(prev => {
        prev.set(agentId, listOptions);
        return prev;
      });
    } finally {
      setIsInlineSelectLoading(false);
    }
  };

  const getFullName = (agent: AgentView) =>
    `${agent.firstName || ''} ${agent.lastName || ''}`.trim();

  const agentNameComparer = (a: AgentView, b: AgentView) =>
    getFullName(a).localeCompare(getFullName(b));

  useEffect(() => {
    if (!agents.data) {
      return;
    }

    setActiveAgents(agents.data.filter(agent => agent.isActive));
  }, [agents]);

  const getResiduals = () => {
    setLoadingResiduals(true);

    api?.residuals
      .getAgentResiduals(searchCriteria, Object.values(filters), selectedAgent?.id)
      .then((result: any) => {
        setResiduals(result || {});
        editOnRender.current = undefined;
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoadingResiduals(false);
      });
  };

  const mapToResidualAdjustments = (
    agentPayoutsToDisplay: AgentPayoutsToDisplay[]
  ): ResidualAdjustments[] => {
    return agentPayoutsToDisplay.map(x => {
      return {
        agentId: x.agentId,
        description: x.description,
        amount: x.amount,
        agentName: x.agentName,
        isManual: true,
        revShare: x.revShare,
      };
    });
  };
  const onSaveManualAdjustments = async () => {
    if (!selectedYearMonth || !agentPayouts) {
      return;
    }
    setIsSaving(true);
    const manualAdjustmentsToSave = agentPayouts.filter(x => x.isManual);
    try {
      const saveResult = await saveResidualManualAdjustments({
        yearMonth: selectedYearMonth,
        manualResidualAdjustmentsToSave: mapToResidualAdjustments(manualAdjustmentsToSave),
        deletedManualResidualAdjustments: mapToResidualAdjustments(manualAdjustmentsToDelete),
      });

      if (saveResult.status === 'error') {
        showNotification('error', saveResult.message);
        return;
      }
      showNotification('success', 'Saved successfully');
      setManualAdjustmentsToDelete([]);
      setManualAdjustmentsWereChanged(false);
    } catch (err: any) {
      console.error(`failed to save. ${err.toString()}`);
      showNotification('error', 'Failed to save.');
    } finally {
      setIsSaving(false);
    }
  };

  const exportToCsv = async () => {
    if (!api) return;

    const options = {
      fieldSeparator: ',',
      filename: `residuals-${selectedYearMonth}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Residuals',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const exportData = await api.residuals.getAgentResidualsExport(
      searchCriteria,
      Object.values(filters),
      selectedAgent?.id
    );

    if (!exportData || exportData.length === 0) return;

    const exporter = new ExportToCsv(options);

    const noNullData = JSON.parse(JSON.stringify(exportData).replace(/null/gi, '""'));

    exporter.generateCsv(noNullData);
  };

  useEffect(() => {
    if (!selectedYearMonth) return;
    getResiduals();
    // eslint-disable-next-line
  }, [searchCriteria, filters, selectedAgent]);

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const handleDateChange = (event: any) => {
    const yearMonth = event.target.value;
    setSelectedYearMonth(yearMonth);
    const forcedFilter = forceActiveFilter(ResidualsFilters, 'yearMonth', '__eq', yearMonth);
    setFilters({...filters, yearMonth: forcedFilter});
  };

  const handleDateChangeWithChangeValidation = (event: any) => {
    if (manualAdjustmentsWereChanged) {
      scrollToSaveManualAdjustment();
      return;
    }
    handleDateChange(event);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFilters({...filters, [filter.option.column]: filter});
  };

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFilters(_filters);
  };

  const formatYearMonth = (yearMonth: string) => {
    const date = parse(yearMonth as string, 'yyyyMM', new Date());
    return format(date, 'MMMM, yyyy');
  };

  const onAddLineItem = (revShare: string) => {
    if (!agentPayouts || !selectedAgent) return;
    const copy = [...agentPayouts];
    copy.push({
      description: 'Description',
      amount: 0,
      agentName: getAgentNames(selectedAgent),
      isManual: true,
      agentId: selectedAgent.id,
      revShare: revShare,
    });
    editOnRender.current = copy.length - 1;
    setTimeout(() => {
      setAgentPayouts(copy);
    }, 200);

    setManualAdjustmentsWereChanged(true);
  };

  const onDeleteLineItemClick = (index: number) => {
    if (!agentPayouts) return;
    const itemToDelete = agentPayouts[index];
    setManualAdjustmentsToDelete(prev => {
      prev.push(itemToDelete);
      return prev;
    });

    const copy = [...agentPayouts];
    const filteredItems = copy.slice(0, index).concat(copy.slice(index + 1, copy.length));
    setAgentPayouts(filteredItems);
    setManualAdjustmentsWereChanged(true);
  };

  const handleLineItemChange = <T extends unknown>(index: number, name: string, value: T) => {
    if (!agentPayouts) return;
    const copy = [...agentPayouts];
    const prevValue = (copy[index] as any)[name];
    if (prevValue === value) {
      return;
    }
    (copy[index] as any)[name] = value;
    setAgentPayouts(copy);
    setManualAdjustmentsWereChanged(true);
  };

  const getBreadcrumbs = () => {
    return [<Typography variant="body1">Agent Residuals</Typography>];
  };

  const getAgentNames = (agent: AgentView): string => {
    return `${agent?.firstName} ${agent?.lastName}`.trim();
  };

  const getDropDownTitle = (agent?: AgentView): string => {
    return agent ? getAgentNames(agent) : 'All Agents';
  };

  if (loadingResiduals) {
    return <Loading />;
  }

  if (!yearMonths || yearMonths.length === 0) {
    return (
      <Box mt={6}>
        <Typography variant="body1" align="center">
          No residuals have been added.
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root} ref={ref}>
      <Title breadcrumbs={getBreadcrumbs()}>
        <div className={classes.grow} />

        <AutoCompleteInputBase
          selected={selectedAgent}
          setSelected={setSelectedAgentWithChangeValidation}
          icon
          placeholder="Select agent..."
          options={selectOptions}
          comparer={agentNameComparer}
          getOptionLabel={(agent: AgentView) => getDropDownTitle(agent)}
          onItemSelect={(agent: AgentView | null) =>
            setSelectedAgentWithChangeValidation(agent ?? undefined)
          }
        />

        <Divider orientation="vertical" className={classes.divider} flexItem />

        {yearMonths && (
          <TextField
            value={selectedYearMonth}
            onChange={handleDateChangeWithChangeValidation}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            {yearMonths.map(e => {
              return (
                <MenuItem key={e} value={e}>
                  {formatYearMonth(e)}
                </MenuItem>
              );
            })}
          </TextField>
        )}
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Filters options={ResidualsFilters} onApplyFilter={handleApplyFilter} />
        <Divider orientation="vertical" className={classes.divider} flexItem />
        <Button onClick={exportToCsv}>Export</Button>
      </Title>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.menuPaper,
        }}
      />

      {residuals && isEmpty(residuals) && (
        <Typography className={classes.noContent}>No Residuals</Typography>
      )}

      {residuals && !isEmpty(residuals) && (
        <>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <Grid item xs={12}>
              {filters && !isEmpty(filters) && (
                <IconButton disabled size="large">
                  <FilterListIcon />
                </IconButton>
              )}

              {filters &&
                Object.keys(filters).map(key => {
                  const filter = filters[key];

                  if (filter.option.type === 'dateRange' && filter.operator.id === '__between') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy h:mm a')}</b>' and '
                            <b>{format(filter.value?.[1] as Date, 'MM/dd/yyyy h:mm a')}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={handleDeleteFilter(key)}
                      />
                    );
                  }

                  if (filter.option.type === 'date' && filter.operator.id === '__between') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{format(filter.value?.[0] as Date, 'MM/dd/yyyy')}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={handleDeleteFilter(key)}
                      />
                    );
                  }

                  if (filter.option.id === 'yearMonth') {
                    return (
                      <Chip
                        key={`${key}-${filter.operator.id}`}
                        className={classes.chip}
                        label={
                          <span>
                            <b>{filter.option.label}</b> {filter.operator.label} '
                            <b>{formatYearMonth(filter.value as string)}</b>'
                          </span>
                        }
                        variant="outlined"
                        onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                      />
                    );
                  }

                  return (
                    <Chip
                      key={`${key}-${filter.operator.id}`}
                      className={classes.chip}
                      label={
                        <span>
                          <b>{filter.option.label}</b> {filter.operator.label} '
                          <b>
                            {filter.option.type === 'currency'
                              ? `$${filter.value}`
                              : `${filter.value}`}
                          </b>
                          '
                        </span>
                      }
                      variant="outlined"
                      onDelete={filter.option.force ? undefined : handleDeleteFilter(key)}
                    />
                  );
                })}
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Visa/MC/Disc Bankcard Transactions': parseFloat(
                    residuals?.transactionCount || '0'
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Amex Bankcard Transactions': parseFloat(residuals?.transactionCountAmex || '0'),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Visa/MC/Disc Bankcard Sales Volume': currentFormatter.format(
                    parseFloat(residuals?.bankcardSalesVolume || '0')
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Amex Bankcard Sales Volume': currentFormatter.format(
                    parseFloat(residuals?.bankcardSalesVolumeAmex || '0')
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'EBT/PIN Debit Total Volume': currentFormatter.format(
                    parseFloat(residuals.pinDebitSalesVolume || '0')
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'EBT/PIN Debit Transaction Count': residuals.pinDebitCount || '0',
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Total Profit': currentFormatter.format(parseFloat(residuals.agentNet || '0')),
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <Square
                center
                lines={{
                  'Residual Paid': currentFormatter.format(
                    parseFloat(residuals.agentNetSplit || '0') + totalAdj
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Box
            component={Paper}
            overflow="auto"
            mt={2}
            sx={[{'& > div:first-child': {width: '100%'}}, {width: {width}}]}
          >
            <DataTable
              noWrap={true}
              columns={
                [
                  {
                    id: 'yearMonth',
                    numeric: false,
                    sortable: true,
                    export: true,
                    label: 'Date',
                    selector: row => {
                      return formatYearMonth(row.yearMonth);
                    },
                  },
                  {
                    id: 'mid',
                    numeric: false,
                    sortable: true,
                    label: 'Merchant ID',
                  },
                  {
                    id: 'agentName',
                    numeric: false,
                    sortable: true,
                    label: 'Agent Name',
                  },
                  {
                    id: 'dba',
                    numeric: false,
                    sortable: true,
                    label: 'DBA',
                  },
                  {
                    id: 'boardedAt',
                    numeric: false,
                    sortable: true,
                    label: 'Boarded Date',
                    export: row => getFormattedDate(row.boardedAt),
                    selector: row => getFormattedDate(row.boardedAt),
                  },
                  {
                    id: 'legalBusinessName',
                    numeric: false,
                    sortable: true,
                    label: 'Legal Name',
                  },
                  {
                    id: 'riskLevel',
                    numeric: false,
                    sortable: true,
                    label: 'Risk Level',
                    selector: row => startcase(row.riskLevel),
                  },
                  {
                    id: 'transactionCount',
                    numeric: true,
                    sortable: true,
                    label: 'Bankcard Transaction Count (Visa/MC/Disc)',
                  },
                  {
                    id: 'transactionCountAmex',
                    numeric: true,
                    sortable: true,
                    label: 'Bankcard Transaction Count (Amex)',
                  },
                  {
                    id: 'bankcardSalesVolume',
                    numeric: true,
                    sortable: true,
                    label: 'Bankcard Sales Volume (Visa/MC/Disc)',
                    selector: row => currentFormatter.format(row.bankcardSalesVolume),
                  },
                  {
                    id: 'bankcardSalesVolumeAmex',
                    numeric: true,
                    sortable: true,
                    label: 'Bankcard Sales Volume (Amex)',
                    selector: row => currentFormatter.format(row.bankcardSalesVolumeAmex),
                  },
                  {
                    id: 'averageTicket',
                    numeric: true,
                    sortable: true,
                    label: 'Bankcard Average Ticket',
                    selector: row => {
                      return row.averageTicket
                        ? currentFormatter.format(+row.averageTicket)
                        : 'N/A';
                    },
                  },
                  {
                    id: 'pinDebitCount',
                    numeric: false,
                    sortable: true,
                    label: 'EBT/PIN Debit Transaction Count',
                    selector: (row: AgentResidual) => row.pinDebitCount ?? '',
                  },
                  {
                    id: 'pinDebitSalesVolume',
                    numeric: false,
                    sortable: true,
                    label: 'EBT/PIN Debit Sales Volume',
                    selector: (row: AgentResidual) =>
                      row.pinDebitSalesVolume
                        ? currentFormatter.format(row.pinDebitSalesVolume)
                        : '',
                  },
                  {
                    id: 'totalIncome',
                    numeric: true,
                    sortable: true,
                    label: 'Merchant Income',
                    selector: row => currentFormatter.format(row.totalIncome),
                  },
                  ...(masterAgentUid
                    ? [
                        {
                          id: 'subAgentExpense',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Expense',
                          selector: (row: AgentResidual) =>
                            row.subAgentExpense ? currentFormatter.format(row.subAgentExpense) : '',
                        },
                        {
                          id: 'subAgentNet',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Net',
                          selector: (row: AgentResidual) =>
                            row.subAgentNet ? currentFormatter.format(row.subAgentNet) : '',
                        },
                        {
                          id: 'subAgentSplitPercent',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Split',
                          selector: (row: AgentResidual) =>
                            row.subAgentSplitPercent ? `${row.subAgentSplitPercent}%` : '',
                        },
                        {
                          id: 'subAgentNetSplit',
                          numeric: true,
                          sortable: true,
                          label: 'Profit',
                          selector: (row: AgentResidual) =>
                            row.subAgentNetSplit
                              ? currentFormatter.format(row.subAgentNetSplit)
                              : '',
                        },
                      ]
                    : [
                        {
                          id: 'agentExpense',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Expense',
                          selector: (row: AgentResidual) =>
                            row.agentExpense ? currentFormatter.format(row.agentExpense) : '',
                        },
                        {
                          id: 'agentNet',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Net',
                          selector: (row: AgentResidual) =>
                            row.agentNet ? currentFormatter.format(row.agentNet) : '',
                        },
                        {
                          id: 'agentSplitPercent',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Split',
                          selector: (row: AgentResidual) =>
                            row.agentSplitPercent ? `${row.agentSplitPercent}%` : '',
                        },
                        {
                          id: 'agentNetSplit',
                          numeric: true,
                          sortable: true,
                          label: 'Agent Profit',
                          selector: (row: AgentResidual) =>
                            row.agentNetSplit ? currentFormatter.format(row.agentNetSplit) : '',
                        },
                        {
                          id: 'subAgentExpense',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Expense',
                          selector: (row: AgentResidual) =>
                            row.subAgentExpense ? currentFormatter.format(row.subAgentExpense) : '',
                        },
                        {
                          id: 'subAgentNet',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Net',
                          selector: (row: AgentResidual) =>
                            row.subAgentNet ? currentFormatter.format(row.subAgentNet) : '',
                        },
                        {
                          id: 'subAgentSplitPercent',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Split',
                          selector: (row: AgentResidual) =>
                            row.subAgentSplitPercent ? `${row.subAgentSplitPercent}%` : '',
                        },
                        {
                          id: 'subAgentNetSplit',
                          numeric: true,
                          sortable: true,
                          label: 'Sub Agent Profit',
                          selector: (row: AgentResidual) =>
                            row.subAgentNetSplit
                              ? currentFormatter.format(row.subAgentNetSplit)
                              : '',
                        },
                        {
                          id: 'agentProfit',
                          numeric: true,
                          sortable: true,
                          label: 'Profit',
                          selector: (row: AgentResidual) =>
                            row.agentProfit ? currentFormatter.format(row.agentProfit) : '',
                        },
                      ]),
                  ...(showShareData
                    ? [
                        {
                          id: 'shareCount',
                          numeric: true,
                          sortable: false,
                          label: 'Share Count',
                        },
                        {
                          id: 'shareSplitPercent',
                          numeric: true,
                          sortable: false,
                          label: 'Share Split',
                          selector: (row: AgentResidual) =>
                            row.shareSplitPercent ? `${row.shareSplitPercent}%` : '',
                        },
                        {
                          id: 'shareNetSplit',
                          numeric: true,
                          sortable: false,
                          label: 'Share Amount',
                          selector: (row: AgentResidual) =>
                            row.shareNetSplit ? currentFormatter.format(row.shareNetSplit) : '',
                        },
                      ]
                    : []),
                ] as Column<
                  AgentResidual & {boardedAt: string | null; averageTicket: string | null}
                >[]
              }
              data={residuals}
              summary={{
                bankcardSalesVolume: currentFormatter.format(
                  parseFloat(residuals?.bankcardSalesVolume || '0')
                ),
                bankcardSalesVolumeAmex: currentFormatter.format(
                  parseFloat(residuals?.bankcardSalesVolumeAmex || '0')
                ),
                transactionCount: parseFloat(residuals?.transactionCount || '0'),
                transactionCountAmex: parseFloat(residuals?.transactionCountAmex || '0'),
                totalIncome: currentFormatter.format(parseFloat(residuals?.totalIncome || '0')),
                agentExpense: currentFormatter.format(parseFloat(residuals?.agentExpense || '0')),
                agentNet: currentFormatter.format(parseFloat(residuals?.agentNet || '0')),
                agentNetSplit: currentFormatter.format(parseFloat(residuals?.agentNetSplit || '0')),
                subAgentExpense: currentFormatter.format(
                  parseFloat(residuals?.subAgentExpense || '0')
                ),
                subAgentNet: currentFormatter.format(parseFloat(residuals?.subAgentNet || '0')),
                subAgentNetSplit: currentFormatter.format(
                  parseFloat(residuals?.subAgentNetSplit || '0')
                ),
                agentProfit: currentFormatter.format(parseFloat(residuals?.agentProfit || '0')),
                shareNetSplit: currentFormatter.format(parseFloat(residuals?.shareNetSplit || '0')),
              }}
              onRowClick={() => {}}
              onRetrieveData={handleRetrieveData}
              paginate
              stickyHeader
              scrollableBody
              customHeight="75vh"
            />

            {!!agentPayouts && (
              <Box mt={2} p={2}>
                <Box display="flex" sx={{maxWidth: 850}}>
                  <Typography variant="h5">Adjustments</Typography>
                  {canAddManualAdjustments && selectedAgent && (
                    <CreateManualAdjustmentBtn
                      options={inlineSelectOptions}
                      loading={isInlineSelectLoading}
                      handleOpen={handleInlineSelectOpen(selectedAgent.id)}
                      onAddLineItem={onAddLineItem}
                    />
                  )}
                </Box>

                <TableContainer component={Paper}>
                  <Table sx={{maxWidth: 850}}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Agent</TableCell>
                        <TableCell align="right" width="200px">
                          Description
                        </TableCell>
                        <TableCell align="right" width="200px">
                          Amount
                        </TableCell>
                        <TableCell align="right" width="140px">
                          Rev Share
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {agentPayouts.map((agentPayout, index) => {
                        return (
                          <Fragment key={`line-item-${index}`}>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                              <TableCell component="th" scope="row">
                                {agentPayout.agentName}
                              </TableCell>

                              <TableCell align="right">
                                {canUpdateManualAdjustments && agentPayout.isManual ? (
                                  <InlineTypographyGeneric
                                    name="description"
                                    editOnRender={editOnRender.current === index}
                                    value={
                                      !!agentPayout.description ? agentPayout.description : '-'
                                    }
                                    onChange={(name, value) => {
                                      handleLineItemChange(index, name, value);
                                    }}
                                    renderLabel={value => value}
                                    validationSchema={yup => yup.string().required()}
                                    TypographyProps={{
                                      color: 'primary',
                                    }}
                                    TextFieldProps={{
                                      variant: 'outlined',
                                      onFocus: event => event.target.select(),
                                      fullWidth: true,
                                    }}
                                  />
                                ) : (
                                  agentPayout.description
                                )}
                              </TableCell>

                              <TableCell
                                align="right"
                                sx={{color: agentPayout.amount >= 0 ? 'green' : 'red'}}
                              >
                                {canUpdateManualAdjustments && agentPayout.isManual ? (
                                  <InlineTypographyGeneric
                                    name="amount"
                                    value={agentPayout.amount}
                                    editOnRender={editOnRender.current === index}
                                    onChange={(name, value) => {
                                      handleLineItemChange(index, name, value);
                                    }}
                                    renderLabel={value => currentFormatter.format(value)}
                                    validationSchema={yup => yup.number().required()}
                                    TextFieldProps={{
                                      variant: 'outlined',
                                      onFocus: event => event.target.select(),
                                      inputProps: {
                                        style: {textAlign: 'right'},
                                      },
                                      fullWidth: false,
                                    }}
                                  />
                                ) : (
                                  currentFormatter.format(agentPayout.amount)
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {agentPayout.isManual && (
                                  <InlineSelect
                                    tooltip="Click to change Rev Share"
                                    handleOpen={handleInlineSelectOpen(agentPayout.agentId)}
                                    value={agentPayout.revShare ?? HundredPercent}
                                    loading={isInlineSelectLoading}
                                    options={inlineSelectOptions}
                                    setValue={newValue =>
                                      handleLineItemChange(index, 'revShare', newValue)
                                    }
                                    tooltipPlacement={TooltipPlacement.BottomStart}
                                    noSort
                                    className={classes.inlineSelect}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {agentPayout.isManual && (
                                  <Tooltip title="Delete Manual Adjustment">
                                    <IconButton
                                      color="default"
                                      aria-label="delete"
                                      disabled={!canUpdateManualAdjustments}
                                      onClick={() => {
                                        onDeleteLineItemClick(index);
                                      }}
                                      size="small"
                                      sx={{padding: 0}}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                      <TableRow key="summaryTotal">
                        <TableCell align="right" colSpan={2} sx={{fontWeight: 500}}>
                          Total Adjustments
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{color: totalAdj >= 0 ? 'green' : 'red', fontWeight: 500}}
                        >
                          {currentFormatter.format(totalAdj)}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow key="summaryNetResidualPaid">
                        <TableCell align="right" colSpan={2} sx={{fontWeight: 500}}>
                          Net Residual Paid
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{color: netResidualPaid >= 0 ? 'green' : 'red', fontWeight: 500}}
                        >
                          {currentFormatter.format(netResidualPaid)}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {(canAddManualAdjustments || canUpdateManualAdjustments) && (
                        <TableRow key="summarySaveManualAdjustments">
                          <TableCell align="right" colSpan={5}>
                            <LoadingButton
                              loading={isSaving}
                              variant="outlined"
                              color="primary"
                              disabled={!manualAdjustmentsWereChanged}
                              onClick={onSaveManualAdjustments}
                            >
                              Save Manual Adjustments
                            </LoadingButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </Box>
        </>
      )}
    </div>
  );
};
