import {AuditableDocument, DocumentBase} from '.';
import {Department} from '../constants';
import {UserRoles} from '../constants/UserRoles';
import {FieldValue, UniversalTimestamp} from './compat';
import {ViewBase} from './ViewBase';
import {defaultViewSelector, ViewSelector} from './ViewSelector';
import {WorkflowEntity} from './Workflow';

export enum TicketStatus {
  Draft = 'draft',
  Open = 'open',
  InProgress = 'doing',
  Replied = 'replied',
  Closed = 'closed',
  Unresolved = 'unresolved',
  Deleted = 'deleted',
}

export const TicketStatusName = {
  [TicketStatus.Draft]: 'Draft',
  [TicketStatus.Open]: 'New',
  [TicketStatus.InProgress]: 'In Progress',
  [TicketStatus.Replied]: 'Replied',
  [TicketStatus.Closed]: 'Resolved',
  [TicketStatus.Unresolved]: 'Unresolved',
  [TicketStatus.Deleted]: 'Deleted',
};

export enum AssigneeType {
  // group = 'Group',
  // crmRole = 'CRM Role', // Agent Support, Underwriting, etc (also called department)
  erpDepartment = 'ERP Department', // Agent Support, Underwriting, etc (also called department)
  erpUser = 'ERP User',
  agent = 'Group Agent',
  merchant = 'Merchant',
}

export type TicketManager = {
  id?: string;
  name?: string;
  department?: string;
};

export enum AuthorType {
  agent = 'Agent',
  merchant = 'Merchant',
  erpUser = 'Erp User',
  workflow = 'Workflow',
}

export type TicketAuthor = TicketManager & {
  id?: string;
  name?: string;
  /** erpUser* | agent | merchant */
  type?: AuthorType;
  description?: AssigneeType;
  department?: Department;
  groupId?: string;
};

export type TicketMerchant = {
  id?: string;
  mid: string;
  dba: string;
  legalName?: string;
};

export type TicketAssignee = {
  /** erpUser* | group | agent | merchant */
  type?: AssigneeType;
  /** either uid or id of group etc */
  erpRole?: UserRoles;
  erpDepartment?: Department;
  erpUser?: TicketManager;
  group?: TicketManager;
  agent?: TicketManager;
  merchant?: TicketMerchant;
};

export enum AssociationType {
  erpUser = 'ERP User',
  group = 'Group',
  agent = 'Agent',
  merchant = 'Merchant',
  application = 'Application',
  mid = 'Merchant ID',
}

export type TicketAccociationApplication = {
  id: string;
  distinguishableId: string;
  name: string;
  mid?: string;
  legalName?: string;
};

export type TicketAssociationMid = {
  mid?: string;
  dba?: string;
};

export type TicketAssociationErpUser = TicketManager & {
  department: Department;
};

export type TicketAssociation = {
  type?: AssociationType;
  erpUser?: TicketAssociationErpUser;
  group?: TicketManager;
  agent?: TicketManager;
  merchant?: TicketMerchant;
  application?: TicketAccociationApplication;
  mid?: TicketAssociationMid;
};

export enum TicketPriority {
  Normal = 'Normal',
  High = 'High',
  Low = 'Low',
}

export type TicketTypeInfo = {
  id: string;
  name: string;
};

export type TicketCategoryInfo = {
  id: string;
  name: string;
  info?: string;
};

export type TicketTypeAndCategory = {
  type?: TicketTypeInfo;
  category?: TicketCategoryInfo;
};

export type TicketLastReplyInfo = {
  commentId: string | null;
  author: string | null;
  authorProfileId: string;
  authorRole: UserRoles | null;
  createdAt: UniversalTimestamp | FieldValue;
};

export type TicketLastCommentInfo = {
  commentId: string | null;
  author: string | null;
  authorProfileId: string;
  authorRole: UserRoles | null;
  authorDepartment: string | null;
  createdAt: UniversalTimestamp | FieldValue;
};

export type LinkedTicket = {
  id: string;
  subject: string;
  distinguishableId?: string;
};

export enum ViewableByType {
  all = 'Agent/Merchant',
  internalOnly = 'Internal Only',
  agentOrErp = 'Agent Only',
}

export type Ticket = AuditableDocument & DocumentBase & TicketData;

export type TicketData = WorkflowEntity & {
  /** Description */
  subject?: string;
  description?: string;

  /** Author/Assignee */
  author?: TicketAuthor;
  assignee?: TicketAssignee;
  association?: TicketAssociation;
  category?: TicketTypeAndCategory;

  distinguishableId?: string;
  labels?: string[];
  priority?: TicketPriority;

  associatedAgents?: string[]; // assigned agent.id + his master agent id if applicable
  associatedGroups?: string[]; // related agents/application/merchants groups ids

  /** Metadata */
  createdAt?: UniversalTimestamp | FieldValue;
  updatedAt?: UniversalTimestamp | FieldValue;
  openedAt?: UniversalTimestamp | FieldValue;
  resolvedAt?: UniversalTimestamp | FieldValue;
  adminsNotifiedAt?: UniversalTimestamp;
  slaBreachedAt?: UniversalTimestamp;
  slaBreachedNotifiedAt?: UniversalTimestamp;
  status?: TicketStatus;
  lastReplyInfo?: TicketLastReplyInfo;
  lastPublicCommentInfo?: TicketLastCommentInfo;
  lastInternalCommentInfo?: TicketLastCommentInfo;
  viewableBy?: ViewableByType;
  linkedTickets?: LinkedTicket[];
};

export type TicketSearchItemModel = {
  id?: string;
  distinguishableId?: string;
  subject?: string;
  description?: string;
  assignee?: string;
  assigneePortal?: string;
  assigneeId?: string;
  association?: string;
  /** used for application id or merchant mid */
  associationId?: string;
  creator?: string;
  creatorPortal?: string;
  labels?: string;
  status?: TicketStatus;
  createdAt?: Date;
  updatedAt?: Date;
  openedAt?: Date;
  sort?: number;
  statusSort?: number;
  authorId?: string;
  ticketViewableBy?: ViewableByType;
  assigneeMerchantId?: string;
  associationMerchantMid?: string;
  associationApplicationMid?: string;
  associatedGroups?: string[];
  associatedAgents?: string[];
  mid?: string;
  dba?: string;
  legalName?: string;
  typeName?: string;
  categoryName?: string;
};

export type TicketView = ViewBase<Ticket>;

export const selectTicketView: ViewSelector<TicketView, Ticket> = snapshot => {
  const ticket: TicketView = {
    ...defaultViewSelector(snapshot),
  };
  return ticket;
};

export type TicketAttachment = {
  id?: string;
  fileName: string;
  fileCloudPath: string;
  createdAt: UniversalTimestamp;
  uid: string;
};

export enum TicketLabels {
  waitingAgentMerchant = 'Waiting on Agent/Merchant',
  waitingSupplier = 'Waiting on Supplier Fulfillment',
  waitingFedEx = 'Waiting on Fed Ex',
  softwareIssue = 'Software Issue',
  waitingInventory = 'Waiting on Inventory',
  missingTicketInfo = 'Missing Ticket Information',
  purchaseDelayed = 'Purchase Delayed Per Management',
  managementEscalation = 'Management Escalation',
  waitingProcessor = 'Waiting on Processor',
  delayedRiskMonitoringInProgress = 'Delayed - Risk Monitoring In Progress',
  boardingCorrection = 'Boarding Correction',
}
