import {
  AssigneeType,
  AssociationType,
  FieldValue,
  TicketAssignee,
  TicketAssociation,
  TicketHistoryRecord,
  UniversalTimestamp,
} from '../../..';
import {TicketWithHistoryAndComments} from '../types';

const formatTimestamp = (timestamp?: UniversalTimestamp | FieldValue) =>
  timestamp && 'seconds' in timestamp ? new Date(timestamp.seconds * 1000).toLocaleString() : '';

function getTicketAssociationName(association?: TicketAssociation): string {
  if (!association || !association.type) return '';

  switch (association.type) {
    case AssociationType.erpUser:
      return association.erpUser?.name || '';
    case AssociationType.group:
      return association.group?.name || '';
    case AssociationType.agent:
      return association.agent?.name || '';
    case AssociationType.merchant:
      return association.merchant?.legalName || '';
    case AssociationType.application:
      return association.application?.name || '';
    case AssociationType.mid:
      return association.mid?.mid || '';
    default:
      return '';
  }
}

function getTicketAssigneeName(assignee?: TicketAssignee): string {
  if (!assignee || !assignee.type) return '';

  switch (assignee.type) {
    case AssigneeType.erpUser:
      return assignee.erpUser?.name || '';
    case AssigneeType.erpDepartment:
      return assignee.erpDepartment || '';
    case AssigneeType.agent:
      return assignee.group?.name || '';
    case AssigneeType.merchant:
      return assignee.merchant?.legalName || '';

    default:
      return '';
  }
}

export function transformTicketsForTable(
  response: TicketWithHistoryAndComments[]
): Record<string, string | undefined>[] {
  return response.map(ticket => {
    const createdDate = formatTimestamp(ticket?.createdAt);
    const categoryName = `${ticket.category?.type?.name}: ${ticket.category?.category?.name}`;

    const assigneeName = ticket.assignee
      ? `${ticket.assignee.type}: ${getTicketAssigneeName(ticket.assignee)}`
      : '';
    const creatorName = ticket.author?.name || '';
    const labels = ticket.labels?.join(', ') || '';
    const slaBreached = ticket.slaBreachedAt ? 'Y' : 'N';
    const association = ticket.association
      ? `${ticket.association.type}: ${getTicketAssociationName(ticket.association)}`
      : '';

    const lastInternalCommentAuthor = ticket.lastInternalCommentInfo?.author || '';
    const lastInternalCommentDate = formatTimestamp(ticket.lastInternalCommentInfo?.createdAt);
    const lastPublicCommentAuthor = ticket.lastPublicCommentInfo?.author || '';
    const lastPublicCommentDate = formatTimestamp(ticket.lastPublicCommentInfo?.createdAt);

    const ticketHistory = ticket.history
      ?.map((h: TicketHistoryRecord) => `${h.title} (${formatTimestamp(h.createdAt)})`)
      .join(',\n');

    return {
      id: ticket.id,
      created: createdDate,
      category: categoryName,
      assignee: assigneeName,
      creator: creatorName,
      association,
      viewableBy: ticket.viewableBy,
      priority: ticket.priority,
      labels,
      subject: ticket.subject,
      description: ticket.description?.replace(/<[^>]*>/g, ''),
      slaBreached,
      lastInternalComment: lastInternalCommentAuthor
        ? `${lastInternalCommentAuthor} (${lastInternalCommentDate})`
        : '',
      lastPublicComment: lastPublicCommentAuthor
        ? `${lastPublicCommentAuthor} (${lastPublicCommentDate})`
        : '',
      ticketHistory,
    };
  });
}
