import {Collections, TicketSubCollections} from '@ozark/functions/src/constants';
import {TicketComment, TicketHistoryRecord} from '@ozark/functions/src/documents';

import {useEffect, useState} from 'react';
import {Firebase} from '../../../firebase';
import {TicketWithHistoryAndComments} from '../types';

export async function getTicketsExport(
  dateFilter: {year: number; month: number},
  numPerPage?: number
) {
  const startDate = new Date(dateFilter.year, dateFilter.month, 1);
  const endDate = new Date(dateFilter.year, dateFilter.month + 1, 1);
  const fetchedTickets: TicketWithHistoryAndComments[] = [];

  let query = Firebase.firestore
    .collection(Collections.tickets)
    .where('createdAt', '>=', startDate)
    .where('createdAt', '<', endDate)
    .orderBy('createdAt', 'asc');

  if (numPerPage) {
    query = query.limit(numPerPage);
  }

  await query.get().then(async snapshot => {
    const tasks: Promise<void>[] = [];
    snapshot.forEach(doc => {
      tasks.push(
        Promise.all([
          doc.ref.collection(TicketSubCollections.history).orderBy('createdAt', 'desc').get(),
          doc.ref.collection(Collections.comments).orderBy('createdAt', 'desc').get(),
          doc.ref.collection(Collections.commentsInternal).orderBy('createdAt', 'desc').get(),
        ]).then(([ticketHistorySnap, commentsSnap, commentsInternalSnap]) => {
          fetchedTickets.push({
            id: doc.id,
            ...doc.data(),
            history: ticketHistorySnap.docs.map(hDoc => hDoc.data() as TicketHistoryRecord),
            comments: commentsSnap.docs.map(hDoc => hDoc.data() as TicketComment),
            commentsInternal: commentsInternalSnap.docs.map(hDoc => hDoc.data() as TicketComment),
          });
        })
      );
    });
    await Promise.all(tasks);
  });

  return fetchedTickets;
}

export function useTicketsExport(
  dateFilter: {year: number; month: number} | undefined,
  pageSize?: number
) {
  const [tickets, setTickets] = useState<TicketWithHistoryAndComments[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!dateFilter) return;
    setLoading(true);
    (async () => {
      const fetchedTickets = await getTicketsExport(dateFilter, pageSize);

      console.log(fetchedTickets);
      setTickets(fetchedTickets);

      setLoading(false);
    })();
  }, [dateFilter, pageSize]);

  return {tickets, loading};
}
