/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {Button, CircularProgress, Divider, MenuItem, TextField, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationLifeStatusFilter,
  ApplicationStatusGroups,
  AsyncState,
  Dispositions,
  getColor,
  getDispositions,
  SearchCriteria,
  useGroupsAndAgents,
  UserRoles,
  Workflows,
} from '@ozark/common';
import {CancelOperationMessage} from '@ozark/common/api/Constants';
import {
  ActiveFilter,
  ApplicationListData,
  Card,
  forceActiveFilter,
  getDispositionFilterValues,
  InfiniteEntities,
  InputSearch,
  PanelItem,
  Title,
  UwApplicationFilters,
} from '@ozark/common/components';
import {useDateRangeApplicationQuery} from '@ozark/common/components/Applications/hooks/useDateRangeApplicationQuery';
import {FilterPeriodSelect} from '@ozark/common/components/FilterPeriodSelect';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {VolumeFilterTypes} from '@ozark/functions/src/functions/express/private/types/Reports';
import {CancelTokenSource} from 'axios';
import {orderBy as orderByLodash} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useActiveProfiles} from '../../hooks/useActiveProfiles';
import {useStore} from '../../store/helpers';
import {ApplicationsExportButton} from '../ApplicationsExportButton';

// TODO replace with sx prop
const useStyles = makeStyles(() =>
  createStyles({
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

const DEFAULT_VALUE = '0';

const viewableDispositions = Workflows[UserRoles.underwriter].viewable;

const defaultFilters = {
  assignedToMe: false,
  vipOnly: false,
  lifeStatus: ApplicationLifeStatusFilter.activeOnly,
  statusGroup: ApplicationStatusGroups.open,
  disposition: null,
};

const defaultPaging: SearchCriteria = {
  // especially for underwriting queue we use pre-defined applications order (see service)
  order: 'desc',
  orderBy: 'createdAt',
  limit: 50, // page size
  offset: 1, // page
};

const addAssignedToMeFilter = (
  target: ActiveFilter[],
  assignedToMe: boolean,
  uid: string
): void => {
  if (!assignedToMe) {
    return;
  }

  target.push(forceActiveFilter(UwApplicationFilters, 'uid', '__eq', uid));
};

const addDispositionsFilter = (
  target: ActiveFilter[],
  disposition: Dispositions | null,
  dispositionGroup: ApplicationStatusGroups
): void => {
  const dispositionFilterValues = getDispositionFilterValues(
    disposition,
    dispositionGroup,
    viewableDispositions
  );

  if (!dispositionFilterValues) {
    return;
  }

  target.push(
    forceActiveFilter(
      UwApplicationFilters,
      'dispositions',
      '__eq',
      dispositionFilterValues.join(',')
    )
  );
};

const addGroupIdFilter = (target: ActiveFilter[], groupId: string | null): void => {
  if (groupId == null || groupId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(UwApplicationFilters, 'groupId', '__eq', groupId));
};

const addAgentFilter = (target: ActiveFilter[], agentId: string | null): void => {
  if (agentId == null || agentId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(UwApplicationFilters, 'agentId', '__eq', agentId));
};

const addUnderwriterFilter = (target: ActiveFilter[], underwriterId: string | null): void => {
  if (underwriterId == null || underwriterId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(UwApplicationFilters, 'uid', '__eq', underwriterId));
};

const addLifeStatusFilter = (target: ActiveFilter[], status: ApplicationLifeStatusFilter): void => {
  let filter: ActiveFilter | null = null;
  switch (status) {
    case ApplicationLifeStatusFilter.activeOnly:
      filter = forceActiveFilter(UwApplicationFilters, 'deleted', '__eq', 'false');
      break;
    case ApplicationLifeStatusFilter.deletedOnly:
      filter = forceActiveFilter(UwApplicationFilters, 'deleted', '__eq', 'true');
      break;
  }
  if (filter != null) {
    target.push(filter);
  }
};

const addIsVipFilter = (target: ActiveFilter[], isVip: boolean): void => {
  if (!isVip) return;
  target.push(forceActiveFilter(UwApplicationFilters, 'isVip', '__eq', 'true'));
};

const buildFilters = (input: {
  uid: string;
  assignedToMe: boolean;
  isVip: boolean;
  lifeStatus: ApplicationLifeStatusFilter;
  statusGroup: ApplicationStatusGroups;
  disposition: Dispositions | null;
  groupId: string | null;
  agentId: string | null;
  underwriterId: string | null;
}): ActiveFilter[] => {
  const result: ActiveFilter[] = [];

  addAssignedToMeFilter(result, input.assignedToMe, input.uid);
  addDispositionsFilter(result, input.disposition, input.statusGroup);
  addGroupIdFilter(result, input.groupId);
  addAgentFilter(result, input.agentId);
  addLifeStatusFilter(result, input.lifeStatus);
  addUnderwriterFilter(result, input.underwriterId);
  addIsVipFilter(result, input.isVip);

  return result;
};

const Underwriting = () => {
  const classes = useStyles();
  const history = useHistory();
  const {apiClient, authProfile, groupsMap, isUserAdmin, profiles} = useStore();
  const activeProfiles = useActiveProfiles(profiles);

  const initialized = useRef(false);

  const [assignedToMe, setAssignedToMe] = useState(defaultFilters.assignedToMe);
  const [statusGroup, setStatusGroup] = useState(defaultFilters.statusGroup);
  const [lifeStatus, setLifeStatus] = useState(defaultFilters.lifeStatus);
  const [vipOnly, setVipOnly] = useState(defaultFilters.vipOnly);
  const [underwriterId, setUnderwriterId] = useState(DEFAULT_VALUE);
  const [disposition, setDisposition] = useState<Dispositions | null>(defaultFilters.disposition);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCriteria, setSearchCriteria] = useState(defaultPaging);
  const {groups, onGroupChange, selectedGroup, filteredAgents, onAgentChange, selectedAgent} =
    useGroupsAndAgents();

  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState(false);
  const [, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
  const {setParam, selectedDateFilter} = useDateRangeApplicationQuery();

  const [applications, setApplications] = useState<AsyncState<ApplicationListData>>({
    promised: true,
  });

  useEffect(() => {
    // This is to prevent the initial load from firing twice in the strict mode.
    if (!initialized.current) {
      initialized.current = true;
      loadData(searchCriteria);
    }
  }, []);

  useEffect(() => {
    const newPaging = {...searchCriteria, offset: 1};
    setSearchCriteria(newPaging);
    loadData(newPaging);
  }, [
    assignedToMe,
    lifeStatus,
    statusGroup,
    disposition,
    searchQuery,
    selectedGroup,
    selectedAgent,
    vipOnly,
    selectedDateFilter,
    underwriterId,
  ]);

  const loadData = (paging: SearchCriteria, onLoaded?: () => void) => {
    if (paging.offset === 1) {
      setIsLoadingFirstPage(true);
    }

    const cancelSource = apiClient?.applications.getCancelTokenSource();

    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    const filters = buildFilters({
      uid: authProfile.data!.id,
      assignedToMe,
      lifeStatus,
      statusGroup,
      isVip: vipOnly,
      disposition,
      underwriterId,
      groupId: selectedGroup,
      agentId: selectedAgent,
    });

    apiClient.applications
      .getUwApplications(selectedDateFilter, paging, searchQuery, filters, cancelSource?.token)
      .then(result => {
        if (result == null) {
          setApplications({promised: false, data: {items: [], hasNextPage: false}});
          return;
        }

        const listData: ApplicationListData = {
          items:
            result.offset === 0
              ? result.data
              : [...(applications.data?.items ?? []), ...result.data],
          hasNextPage: result.data.length === result.limit || false,
          totalCount: result.totalCount,
        };
        setApplications({promised: false, data: listData});
        setIsLoadingFirstPage(false);
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        setApplications({promised: false, error: err || {}});
        setIsLoadingFirstPage(false);
      })
      .finally(() => {
        onLoaded?.();
      });
  };

  const loadNextPage = (onLoaded?: () => void): void => {
    const newValue = {...searchCriteria, offset: searchCriteria.offset + 1};
    setSearchCriteria(newValue);
    loadData(newValue, onLoaded);
  };

  const fetchAll = async () => {
    let allApplications: ApplicationResponse[] = [];
    const cancelSource = apiClient?.applications.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    const filters = buildFilters({
      uid: authProfile.data!.id,
      assignedToMe,
      lifeStatus,
      isVip: vipOnly,
      statusGroup,
      disposition,
      underwriterId,
      groupId: selectedGroup,
      agentId: selectedAgent,
    });

    try {
      const result = await apiClient.applications.getUwApplications(
        selectedDateFilter,
        {...searchCriteria, limit: 0},
        searchQuery,
        filters,
        cancelSource?.token
      );

      if (result == null) {
        return [];
      }

      allApplications = result.data;
    } catch (err: any) {
      console.error(err);
    }

    return allApplications;
  };

  const toggleAssignedToMe = () => {
    setUnderwriterId(DEFAULT_VALUE);
    setAssignedToMe(!assignedToMe);
  };

  const handleUnderwriterChange = (event: any) => {
    setAssignedToMe(defaultFilters.assignedToMe);
    setUnderwriterId(event.target.value);
  };

  const handleStatusGroupChange = (event: any) => {
    setStatusGroup(event.target.value);
    setDisposition(null);
  };

  const handleDispositionChange = (event: any) =>
    setDisposition(event.target.value === 'null' ? null : event.target.value);

  const handleGroupChange = (event: any) => onGroupChange(event.target.value);

  const handleAgentChange = (event: any) => onAgentChange(event.target.value);

  const handleLifeStatusChange = (event: any) => setLifeStatus(event.target.value);

  const handleDateFilterChange = (val: any) => {
    setParam({
      selectedDateFilter: val.type,
      ...(val.startDate && {startDate: val.startDate.toString()}),
      ...(val.endDate && {endDate: val.endDate.toString()}),
    });
  };

  const toggleShowVipOnly = () => {
    setVipOnly(!vipOnly);
  };

  return (
    <Box
      sx={{
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{display: 'flex'}}>
        <Title
          sx={{width: 'auto'}}
          noBorder
          breadcrumbs={[
            <Link
              component="button"
              variant="body1"
              onClick={() => history.push(ROUTES.UNDERWRITING)}
            >
              Underwriting
            </Link>,
          ]}
        />
        <Box sx={{flexGrow: 1}}>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              justifyContent: 'end',
              alignItems: 'center',
              marginY: 1,
            }}
          >
            <PanelItem sx={{gridColumn: 'span 2'}}>
              <FilterPeriodSelect
                onSelect={handleDateFilterChange}
                selected={selectedDateFilter}
                visibleVolumeFilterTypes={[
                  VolumeFilterTypes.Today,
                  VolumeFilterTypes.WTD,
                  VolumeFilterTypes.MTD,
                  VolumeFilterTypes.QTD,
                  VolumeFilterTypes.YTD,
                  VolumeFilterTypes.All,
                  VolumeFilterTypes.Custom,
                ]}
              />
            </PanelItem>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <PanelItem>
              <TextField
                value={underwriterId ?? DEFAULT_VALUE}
                onChange={handleUnderwriterChange}
                label="Filter by Underwriter"
                sx={{width: '100%'}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem value={DEFAULT_VALUE}>All</MenuItem>
                {(activeProfiles?.byRole[UserRoles.underwriter] ?? []).map(user => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.displayName}
                  </MenuItem>
                ))}
              </TextField>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <TextField
                value={selectedGroup ?? DEFAULT_VALUE}
                onChange={handleGroupChange}
                label="Filter by Group"
                sx={{width: '100%'}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem value="0">All</MenuItem>
                {(groups.data ?? []).sortAndMap(
                  group => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ),
                  group => group.name
                )}
              </TextField>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <TextField
                value={selectedAgent ?? DEFAULT_VALUE}
                onChange={handleAgentChange}
                label="Filter by Agent"
                sx={{width: '100%'}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem value="0">All</MenuItem>
                {orderByLodash(filteredAgents ?? [], ['firstName', 'lastName']).map(agent => (
                  <MenuItem key={agent.id} value={agent.id}>
                    {agent.firstName} {agent.lastName}
                  </MenuItem>
                ))}
              </TextField>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <TextField
                value={statusGroup}
                onChange={handleStatusGroupChange}
                variant="standard"
                label="Filter by Status"
                sx={{width: '100%'}}
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                {Object.values(ApplicationStatusGroups)
                  .filter(e => getDispositions(e, viewableDispositions).length > 0)
                  .sortAndMap(e => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
              </TextField>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <TextField
                value={String(disposition)}
                onChange={handleDispositionChange}
                label="Filter by Disposition"
                sx={{width: '100%'}}
                variant="standard"
                InputProps={{
                  classes: {
                    input: classes.selectInput,
                  },
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem value={String(null)}>
                  {statusGroup === ApplicationStatusGroups.all
                    ? 'All Dispositions'
                    : `All ${statusGroup} Dispositions`}
                </MenuItem>
                {getDispositions(statusGroup, viewableDispositions).sortAndMap(e => (
                  <MenuItem key={e} value={e}>
                    <span style={{color: getColor(e)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
                    {e}
                  </MenuItem>
                ))}
              </TextField>
            </PanelItem>
            {isUserAdmin() && (
              <PanelItem dividerPosition="start">
                <TextField
                  value={lifeStatus}
                  onChange={handleLifeStatusChange}
                  variant="standard"
                  label="Filter by State"
                  sx={{width: '100%'}}
                  InputProps={{
                    classes: {
                      input: classes.selectInput,
                    },
                    disableUnderline: true,
                  }}
                  select
                >
                  {Object.values(ApplicationLifeStatusFilter).sortAndMap(e => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </TextField>
              </PanelItem>
            )}
          </Box>

          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <PanelItem sx={{gridColumn: 'span 2'}}>
              <InputSearch
                fieldName="searchApplications"
                placeholder="Search..."
                onSearchChange={setSearchQuery}
                fullWidth
              />
            </PanelItem>

            <PanelItem dividerPosition="start">
              <Button
                size="small"
                onClick={toggleAssignedToMe}
                startIcon={
                  assignedToMe ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />
                }
              >
                Assigned to me
              </Button>
            </PanelItem>
            <PanelItem dividerPosition="start">
              <Button
                size="small"
                onClick={toggleShowVipOnly}
                startIcon={
                  vipOnly ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />
                }
              >
                VIP/Priority
              </Button>
            </PanelItem>
            {isUserAdmin() && (
              <ApplicationsExportButton
                filename="underwriting-applications"
                getRows={fetchAll}
                rows={applications.data?.items}
                sx={{ml: 2, alignSelf: 'center', justifySelf: 'center'}}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Divider sx={{margin: '5px 0'}} />

      {isLoadingFirstPage || applications.promised ? (
        <Box sx={{position: 'relative', top: '40%', m: '0 auto'}}>
          <CircularProgress color="primary" />
        </Box>
      ) : !applications.data?.items?.length ? (
        <Typography sx={{top: '40%', position: 'relative', textAlign: 'center'}}>
          No Applications
        </Typography>
      ) : (
        <InfiniteEntities<ApplicationResponse>
          showTotal
          data={applications.data}
          itemSize={270}
          loadNextPage={loadNextPage}
          onRender={application => (
            <Card
              application={application}
              customizeTransferable
              onClick={() =>
                history.push(`underwriting/${application.id}`, {referrer: 'Underwriting'})
              }
              profiles={profiles}
              group={groupsMap[application.group.id]}
            />
          )}
        />
      )}
    </Box>
  );
};

export default Underwriting;
